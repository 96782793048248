<template>
  <div class="elv-base-cell-vertical-text" :class="props.align ? `elv-base-cell-vertical-text-${props.align}` : ''">
    <div
      class="elv-base-cell-vertical-text-up"
      :title="props.upDesc"
      :style="{
        fontWeight: props.upWeight || 400,
        fontFamily: props.upFontFamily || 'Barlow',
        fontSize: props.upFontSize || '13px'
      }"
    >
      {{ props.upText }}
    </div>
    <div
      class="elv-base-cell-vertical-text-down"
      :title="props.downDesc"
      :style="{
        fontWeight: props.downWeight || 400,
        fontFamily: props.downFontFamily || 'Barlow',
        fontSize: props.downFontSize || '11px'
      }"
    >
      {{ props.downText }}
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  upText: string
  upFontFamily?: string
  upFontSize?: string
  upWeight?: number
  upDesc?: string
  downWeight?: number
  downText: string
  downFontFamily?: string
  downFontSize?: string
  downDesc?: string
  align?: string
}>()
</script>
<style lang="scss">
.elv-base-cell-vertical-text {
  color: #0e0f11;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;

  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }

  &-up {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    line-height: normal;
    white-space: nowrap;
  }

  &-down {
    color: #838d95;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
